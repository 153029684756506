import { createSlice } from "@reduxjs/toolkit"
import { calculationActionCreators } from "../actionCreators/calculationActionCreators"
import { Country, MakeCalculationResponseBody, NewTxDetailsFormFieldsConfig, Payer, Service } from "@customTypes/calculation"
import { LoadState } from "@customTypes/common"
import calculationUtils from "@utils/calculation"

type CalculationSlice = {
  sourceCountry: Array<Country>
  destCountry: Array<Country>
  services: Array<Service>
  payers: Array<Payer>
  calculationInfo: MakeCalculationResponseBody | null
  // newTxDetailsFormFieldsConfig: NewTxDetailsFormFieldsConfig | null

  makeCalculationLoadState: LoadState
}

const initialState: CalculationSlice = {
  sourceCountry: [],
  destCountry: [],
  services: [],
  payers: [],
  calculationInfo: null,
  // newTxDetailsFormFieldsConfig: null,

  makeCalculationLoadState: {
    isLoading: false,
    isLoaded: false,
  }
}

export const calculationSlice = createSlice({
  name: 'calculation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(calculationActionCreators.makeInitialCalculation.pending, state => {
        state.makeCalculationLoadState = {
          isLoaded: false,
          isLoading: true,
        }
      })
      .addCase(calculationActionCreators.makeInitialCalculation.fulfilled, (state, action) => {
        state.sourceCountry = action.payload.countries.source
        state.destCountry = action.payload.countries.destination
        state.services = action.payload.services
        state.payers = action.payload.payers
        state.calculationInfo = action.payload.calculationResponse
        state.makeCalculationLoadState = {
          isLoaded: true,
          isLoading: false,
        }
      })
      .addCase(calculationActionCreators.makeInitialCalculation.rejected, state => {
        state.makeCalculationLoadState = {
          isLoaded: false,
          isLoading: false,
        }
      })
      .addCase(calculationActionCreators.makeCalculation.pending, state => {
        state.makeCalculationLoadState = {
          isLoaded: false,
          isLoading: true,
        }

        // state.calculationInfo = null
      })
      .addCase(calculationActionCreators.makeCalculation.fulfilled, (state, action) => {
        state.calculationInfo = action.payload

        state.makeCalculationLoadState = {
          isLoaded: true,
          isLoading: false,
        }
      })
      .addCase(calculationActionCreators.makeCalculation.rejected, state => {
        state.makeCalculationLoadState = {
          isLoaded: false,
          isLoading: false,
        }
      })
      .addCase(calculationActionCreators.makeCalculationDueToReceiveMethod.pending, state => {
        state.makeCalculationLoadState = {
          isLoaded: false,
          isLoading: true,
        }

        state.calculationInfo = null
      })
      .addCase(calculationActionCreators.makeCalculationDueToReceiveMethod.fulfilled, (state, action) => {
        state.calculationInfo = action.payload.calculationResponse
        state.payers = action.payload.payers


        state.makeCalculationLoadState = {
          isLoaded: true,
          isLoading: false,
        }
      })
      .addCase(calculationActionCreators.makeCalculationDueToReceiveMethod.rejected, state => {
        state.makeCalculationLoadState = {
          isLoaded: false,
          isLoading: false,
        }
      })

      .addCase(calculationActionCreators.makeCalculationDueToDestCountry.pending, state => {
        state.makeCalculationLoadState = {
          isLoaded: false,
          isLoading: true,
        }

        state.calculationInfo = null
      })
      .addCase(calculationActionCreators.makeCalculationDueToDestCountry.fulfilled, (state, action) => {
        state.calculationInfo = action.payload.calculationResponse
        state.services = action.payload.services
        state.payers = action.payload.payers


        state.makeCalculationLoadState = {
          isLoaded: true,
          isLoading: false,
        }
      })
      .addCase(calculationActionCreators.makeCalculationDueToDestCountry.rejected, state => {
        state.makeCalculationLoadState = {
          isLoaded: false,
          isLoading: false,
        }
      })


  }
})

export default calculationSlice.reducer