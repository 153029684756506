import AgentBranchesTable from '@components/agentBranches/AgentBranchesTable'

const AgentBranches = () => {
  return (
    <section>
      <div className="main-padding-container">
        <h3 className="t-center mb-24">Agent Branches</h3>
        <div >
          <p
            className="t-center mb-24"
            style={{
              backgroundColor: '#FF7373',
              padding: '16px 16px',
              borderRadius: '10px',
              marginInline: 'auto',
              color: 'white',
              width: 'fit-content',
              fontWeight: '700'
            }}
          >
            Great, your pricing is locked in. Please select branch to pay into.
          </p>
        </div>

        <div className="background-container">
          <div className="box is-transparent">
            <AgentBranchesTable />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AgentBranches
