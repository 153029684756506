import React, { useMemo } from 'react'
import { Control } from 'react-hook-form'
import FormInput from './FormInput'
import FormPhoneNumberInput from './FormPhoneNumberInput'
import { NewTxDetailsFormType } from '@customTypes/transaction'


type CommonCreditPartyFormFieldProps = {
  name: string
  control: Control<NewTxDetailsFormType>
  destinationCountryIso2Code: string
}

const CommonCreditPartyFormField = ({ name, control, destinationCountryIso2Code }: CommonCreditPartyFormFieldProps) => {
  const renderView = useMemo(() => {
    switch (name) {
      case 'msisdn': {
        return (
          <FormPhoneNumberInput<NewTxDetailsFormType>
          name="creditPartyIdentifier.msisdn"
          control={control}
          placeholder="Mobile number"
          label="Mobile number"
          defaultCountryIso2Code={destinationCountryIso2Code}
        />
        )
      }
      case 'iban': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="creditPartyIdentifier.iban"
            control={control}
            placeholder="IBAN"
            label="IBAN"
            type="text"
            maxLength={64}
          />
        )
      }
      case 'swift_bic_code': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="creditPartyIdentifier.swift_bic_code"
            control={control}
            placeholder="SWIFT/BIC code"
            label="SWIFT/BIC code"
            type="text"
            maxLength={32}
          />
        )
      }
      case 'bank_account_number': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="creditPartyIdentifier.bank_account_number"
            control={control}
            placeholder="Bank account number"
            label="Bank account number"
            type="text"
            maxLength={64}
          />
        )
      }
      case 'account_number': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="creditPartyIdentifier.account_number"
            control={control}
            placeholder="Account number"
            label="Account number"
            type="text"
            maxLength={64}
          />
        )
      }
      case 'ifs_code': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="creditPartyIdentifier.ifs_code"
            control={control}
            placeholder="IFSC code"
            label="IFSC code"
            type="text"
            maxLength={11}
          />
        )
      }
      case 'sort_code': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="creditPartyIdentifier.sort_code"
            control={control}
            placeholder="Sort code"
            label="Sort code"
            type="text"
            maxLength={12}
          />
        )
      }
      case 'bsb_number': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="creditPartyIdentifier.bsb_number"
            control={control}
            placeholder="BSB number"
            label="BSB number"
            type="text"
            maxLength={9}
          />
        )
      }
      case 'email': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="creditPartyIdentifier.email"
            control={control}
            placeholder="Email"
            label="Email"
            type="text"
            maxLength={64}
          />
        )
      }
      case 'routing_code': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="creditPartyIdentifier.routing_code"
            control={control}
            placeholder="Routing code/Transit number"
            label="Routing code/Transit number"
            type="text"
            maxLength={15}
          />
        )
      }
    }
  }, [control, destinationCountryIso2Code, name])
  return renderView
}

export default CommonCreditPartyFormField
