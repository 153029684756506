const agentBranchesData = [
  {
    branch: 'Quest Rochester',
    city: 'Harare',
    address: '6 Rochester Crescent, Alexandra Park',
    openingHours: 'Monday - Friday 10.00 Hrs - 15.00 Hrs',
    coordinates: '-17.7958177, 31.0469257'
  },
  {
    branch: 'Quest Finsure House',
    city: 'Harare',
    address: 'Ground Floor, Finsure House, Cnr Sam Nujoma St/ Kwame Nkrumah Avenue',
    openingHours: 'Monday - Friday 10.00 Hrs - 15.00 Hrs',
    coordinates: '-17.8275394, 31.0509629'
  },
  {
    branch: 'Quest Social Security Centre SSC',
    city: 'Harare',
    address: 'Ground Floor, SSC Building, Cnr Sam Nujoma St/Julius Nyerere Way',
    openingHours: 'Monday - Friday 10.00 Hrs - 15.00 Hrs',
    coordinates: '-17.8247193, 31.0497616'
  },
  {
    branch: 'Quest Throgmorton House',
    city: 'Harare',
    address: 'Ground floor, Throgmorton House, Samora Machel Ave/Julius Nyerere Way',
    openingHours: 'Monday - Friday 10.00 Hrs - 15.00 Hrs',
    coordinates: '-17.8274373, 31.0471024'
  },
  {
    branch: 'Quest Chisipite',
    city: 'Harare',
    address: '178 Enterprises Road, Harris Auto Complex, Chisipite',
    openingHours: 'Monday - Friday 10.00 Hrs - 15.00 Hrs',
    coordinates: '-17.7891392 31.1092647',
  },
  {
    branch: 'Quest Highglen',
    city: 'Harare',
    address: 'Shop 61 Highglen shopping complex',
    openingHours: 'Monday - Friday 10.00 Hrs - 15.00 Hrs',
    coordinates: '-17.8823786 30.9594673'
  },
  {
    branch: 'Quest Eastgate branch',
    city: 'Harare',
    address: 'shop 27, Ground floor, Eastgate shopping complex',
    openingHours: 'Monday - Friday 10.00 Hrs - 15.00 Hrs',
    coordinates: '-17.831452, 31.052619'
  },
  {
    branch: 'Quest Bulawayo',
    city: 'Bulawayo',
    address: '1st floor, CABS Centre, Jason Moyo Street, between 9th and 10th Avenue',
    openingHours: 'Monday - Friday 10.00 Hrs - 15.00 Hrs',
    coordinates: '-20.1571061 28.5825755'
  },
  {
    branch: 'Mastreetz Service Office',
    city: 'Harare',
    address: '15 Shortheath Road, Chisipite',
    openingHours: 'Monday - Friday 10.00 Hrs - 15.00 Hrs',
    coordinates: '-17.7862458 31.1181888'
  },
]

export default agentBranchesData