import axios, { AxiosError, Method } from 'axios'
import localStorageData from '@utils/localStorage'
import { combineUrl } from './utils'
import type { CustomErrorResponse } from '@customTypes/common'
import { auth } from '@fire-base/mainFirebase'
import authServices from './services/authServices'
// import { authServices } from './services/authServices'

export const ownAuthAxios = axios.create()

ownAuthAxios.interceptors.response.use(
  async response => response,
  async function (error: AxiosError) {
    const customError = error?.response?.data as CustomErrorResponse

    if (error.response?.status === 401 && customError.errorCode === '401001001') {
      try {
        const response = await auth.currentUser?.getIdToken(true)
        localStorageData.setData('authData', response)

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const body = {
          providerId: auth.currentUser?.providerData[0].providerId || '',
          accessToken: response || '',
          // refreshToken: userFirebaseRefreshToken,
          timezone: userTimeZone,
        }

        await authServices.authorizeUser(body)


        if (error.config) {
          error.config.headers!['Authorization'] = `Bearer ${response}`
          return await ownAuthAxios.request(error.config)
        }
      } catch (error) {
        authServices.logOut()
        authServices.logOutFirebase()
        window.location.href = '/'

        return Promise.reject('session expired!')
      }
    }

    if (error.response?.status === 401 && customError.errorCode === '401001003') {
      authServices.logOut()
      authServices.logOutFirebase()
      window.location.href = '/'
    }

    if (error.response?.status === 413) {
      const currentError = {
        errorCode: 413,
        message: 'Content Too Large',
      }

      return Promise.reject(currentError)
    }

    // if (
    //   error.response?.status &&
    //   error.response?.status >= 500 &&
    //   error.response?.status < 600 &&
    //   customError.errorCode !== '5000000148' &&
    //   customError.errorCode !== '5000000158'
    // ) {
    //   history.push('/maintenance')
    // }

    // if (error.response?.status === 403) {
    //   // await authServices.logOut()
    //   // localStorageHelper.clearLocalStorage()
    //   // store.dispatch({ type: authConstants.LOGOUT_SUCCESS })
    //   history.push('/')
    //   store.dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: '403' } })
    // }

    return Promise.reject(error)
  },
)

export const authorizedRequest = async <TRequest, TResponse>(
  defaultApiUrl: 'v1' | 'v2',
  path: string[],
  method: Method = 'GET',
  body?: TRequest,
  queryParams?: Record<string, string | number | Array<number | string>> | null,
  contentType: string = 'application/json'
  // isCancelledMode?: boolean,
): Promise<TResponse> => {
  const authData = localStorageData.getData('authData')
  const upperCaseMethod = method.toUpperCase()
  const apiUrl = import.meta.env.VITE_API_URL
  // const defaultApiUrl = 'v1'

  const requestUri = combineUrl(apiUrl, defaultApiUrl, ...path)

  // const controller = new AbortController()

  // if (isCancelledMode) {
  //   abortControllerObj[url]?.abort()
  //   abortControllerObj[url] = controller
  // }


  let response
  try {
    response = await ownAuthAxios({
      method: upperCaseMethod,
      baseURL: requestUri,
      headers: {
        Authorization: `Bearer ${authData}`,
        // 'Content-Type': 'application/json',
        'Content-Type': contentType,
      },
      ...(body && { data: body }),
      responseType: 'json',
      params: queryParams,
      paramsSerializer: {
        indexes: null,
      },
      // ...(isCancelledMode && { signal: controller.signal }),
    })
    // controller.abort()
    return response.data as TResponse
  } catch (error) {
    const e = error as AxiosError

    if (e.request.response) {
      const customErrorResponse = JSON.parse(e.request.response) as CustomErrorResponse
      return Promise.reject(customErrorResponse)
    } else {
      return Promise.reject({ message: e.message })
    }

  }
}

// export const getFile = async (
// 	path: string[],
// 	queryParams?: Record<string, string | number | Array<string | number>>,
// ) => {
// 	const authData = localStorageData.getData('authData')
// 	const apiUrl = process.env.REACT_APP_BASE_URL || ''
// 	const defaultApiUrl = 'api/v1/master-agent'

// 	const requestUri = combineUrl(apiUrl, defaultApiUrl, ...path)

// 	let response
// 	try {
// 		response = await ownAuthAxios({
// 			method: 'GET',
// 			baseURL: requestUri,
// 			headers: {
// 				Authorization: `Bearer ${authData.accessToken}`,
// 				'Content-Type': 'text/csv',
// 			},
// 			responseType: 'blob',
// 			params: queryParams,
// 			paramsSerializer: { indexes: null },
// 		})

// 		return response
// 	} catch (error) {
// 		let e = error as AxiosError
// 		let customErrorResponse = JSON.parse(e.request.response) as CustomErrorResponse
// 		return Promise.reject(customErrorResponse)
// 	}
// }
