import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import Icon from '@components/Icon'
import { LoginScreenState, SignUpArgsRequest, SignUpFormType } from '@customTypes/auth'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppSelector } from '@hooks/reduxHooks'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { useForm } from 'react-hook-form'
import FormInput from '@components/newTx/formScreen/FormInput'
import commonUtils from '@utils/common'
import loginYupSchemes from '@utils/yupSchemes/loginYupSchemes'
import PasswordTextFieldForm from '@components/calculation/ui/PasswordTextFieldForm'

type SignUpFormProps = {
  onParentSubmit: (formData: SignUpArgsRequest) => void
  userData: SignUpArgsRequest
  setCurrentScreen: Dispatch<SetStateAction<LoginScreenState>>
}

const SignUpForm = ({ onParentSubmit, setCurrentScreen, userData }: SignUpFormProps) => {
  const signUpIsLoading = useAppSelector(state => state.auth.signUpLoadState.isLoading)

  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [isExistLowercaseChar, setIsExistLowercaseChar] = useState(false)
  const [isExistUppercaseChar, setIsExistUppercaseChar] = useState(false)
  const [isExistNumber, setIsExistNumber] = useState(false)
  const [isMinCharNumber, setIsMinCharNumber] = useState(false)

  const { handleSubmit, control, formState, setValue, watch } = useForm<SignUpFormType>({
    resolver: yupResolver(loginYupSchemes.signUpFormScheme),
  })

  const watchedPassword = watch('password')

  const checkPassword = useCallback(() => {
    const lowercasePattern = /[a-z]/
    const uppercasePattern = /[A-Z]/
    const numberPattern = /\d/
    const isMinCharNumber = watchedPassword?.length >= 8

    setIsExistLowercaseChar(lowercasePattern.test(watchedPassword))

    setIsExistUppercaseChar(uppercasePattern.test(watchedPassword))

    setIsExistNumber(numberPattern.test(watchedPassword))

    setIsMinCharNumber(isMinCharNumber)
  }, [watchedPassword])

  useEffect(() => {
    checkPassword()
  }, [checkPassword])

  const setFormValue = useCallback(() => {
    setValue('email', userData.email)
    setValue('firstName', userData.firstName)
    setValue('lastName', userData.lastName)
    setValue('password', '')
  }, [setValue, userData.email, userData.firstName, userData.lastName])

  useEffect(() => {
    setFormValue()
  }, [setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: SignUpFormType) => {
    const trimmedDataForm = commonUtils.trimObjectFields(
      omit(dataForm, ['confirmPassword'])
    ) as SignUpArgsRequest

    onParentSubmit(trimmedDataForm)
  }

  const onBack = useCallback(() => {
    setCurrentScreen('email')
  }, [setCurrentScreen])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="box d-flex flex-col gap-24 is-white">
        <div>
          <div className="d-flex align-items-center gap-8 mb-16">
            <button className="transparent-button" onClick={onBack}>
              <Icon name="arrow-left" size={20} />
            </button>
            <h4>Sign Up</h4>
          </div>
          <FormInput
            name="email"
            control={control}
            placeholder="Enter email"
            type="string"
            label="Email"
            maxLength={64}
            autoComplete="on"
          />
        </div>
        <PasswordTextFieldForm
          name="password"
          control={control}
          placeholder="Enter password"
          label="* Password"
          maxLength={32}
          isShownVisibleBtn
        />
        <div className="d-flex flex-col">
          <p
            style={{ color: isExistLowercaseChar ? 'green' : '#D6D6D6', fontSize: '12px' }}
            className="d-flex align-items-center gap-8 mt-4 mb-4 px-8"
          >
            <Icon
              size={16}
              name="check-mark"
              color={isExistLowercaseChar ? 'green' : '#D6D6D6'}
            />{' '}
            One lowercase character
          </p>
          <p
            style={{ color: isExistUppercaseChar ? 'green' : '#D6D6D6', fontSize: '12px' }}
            className="d-flex align-items-center gap-8 mt-4 mb-4 px-8"
          >
            <Icon
              size={16}
              name="check-mark"
              color={isExistUppercaseChar ? 'green' : '#D6D6D6'}
            />
            One uppercase character
          </p>
          <p
            style={{ color: isExistNumber ? 'green' : '#D6D6D6', fontSize: '12px' }}
            className="d-flex align-items-center gap-8 mt-4 mb-4 px-8"
          >
            <Icon
              size={16}
              name="check-mark"
              color={isExistNumber ? 'green' : '#D6D6D6'}
            />
            One number
          </p>
          <p
            style={{ color: isMinCharNumber ? 'green' : '#D6D6D6', fontSize: '12px' }}
            className="d-flex align-items-center gap-8 mt-4 mb-4 px-8"
          >
            <Icon
              size={16}
              name="check-mark"
              color={isMinCharNumber ? 'green' : '#D6D6D6'}
            />
            8 characters minimum
          </p>
        </div>
        <PasswordTextFieldForm
          name="confirmPassword"
          control={control}
          placeholder="Enter confirm password"
          label="Confirm password"
          maxLength={32}
          isShownVisibleBtn={false}
        />
        <FormInput
          name="firstName"
          control={control}
          placeholder="Enter first name"
          type="text"
          label="First Name"
          maxLength={64}
        />
        <FormInput
          name="lastName"
          control={control}
          placeholder="Enter last name"
          type="text"
          label="Last Name"
          maxLength={64}
        />
        <div className="form-control-hint">
          * Password must contain at least one: number (0-9), upper case letter [A-Z], lower case
          letter [a-z] and have min 8 symbols
        </div>
        <button
          className="btn btn-primary w-100"
          disabled={
            isDisabledButton ||
            signUpIsLoading ||
            !isExistLowercaseChar ||
            !isExistUppercaseChar ||
            !isExistNumber ||
            !isMinCharNumber
          }
          type="submit"
        >
          Sign Up
        </button>
      </form>
    </>
  )
}

export default SignUpForm
