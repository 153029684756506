import authActionCreators from "@actionCreators/authActionCreators"
import { LoadState } from "@customTypes/common"
import { createSlice } from "@reduxjs/toolkit"

type AuthSlice = {
  isLoginCurrentSession: boolean

  signUpLoadState: LoadState
  signInViaEmailAndPasswordLoadState: LoadState
  sendEmailToRestPasswordLoadState: LoadState
}

const initialState: AuthSlice = {
  isLoginCurrentSession: false,

  signUpLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  signInViaEmailAndPasswordLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  sendEmailToRestPasswordLoadState: {
    isLoading: false,
    isLoaded: false,
  },
}


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActionCreators.signInFirebaseWithGoogle.fulfilled, state => {
        state.isLoginCurrentSession = true
      })

      .addCase(authActionCreators.signUp.pending, state => {
        state.signUpLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(authActionCreators.signUp.fulfilled, (state, action) => {
        state.signUpLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.isLoginCurrentSession = true
      })
      .addCase(authActionCreators.signUp.rejected, state => {
        state.signUpLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      .addCase(authActionCreators.signInViaEmailAndPassword.pending, state => {
        state.signInViaEmailAndPasswordLoadState = {
          isLoading: true,
          isLoaded: false
        }
      })
      .addCase(authActionCreators.signInViaEmailAndPassword.fulfilled, state => {
        state.signInViaEmailAndPasswordLoadState = {
          isLoading: false,
          isLoaded: true
        }
        state.isLoginCurrentSession = true
      })
      .addCase(authActionCreators.signInViaEmailAndPassword.rejected, state => {
        state.signInViaEmailAndPasswordLoadState = {
          isLoading: false,
          isLoaded: false
        }
      })

      .addCase(authActionCreators.sendEmailToResetPassword.pending, state => {
        state.sendEmailToRestPasswordLoadState = {
          isLoading: true,
          isLoaded: false
        }
      })
      .addCase(authActionCreators.sendEmailToResetPassword.fulfilled, state => {
        state.sendEmailToRestPasswordLoadState = {
          isLoading: false,
          isLoaded: true
        }
      })
      .addCase(authActionCreators.sendEmailToResetPassword.rejected, state => {
        state.sendEmailToRestPasswordLoadState = {
          isLoading: false,
          isLoaded: false
        }
      })


      .addCase(authActionCreators.logOut.fulfilled, () => initialState)

  }
})

export default authSlice.reducer