import { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import router from '@routes/routerConfig'
import ErrorModal from '@components/calculation/ui/ErrorModal'
import TagManager from 'react-gtm-module'

import '@fontsource/manrope'
import '@fontsource/manrope/700.css'
import '@fontsource/manrope/500.css'
import '@fontsource/poppins'
import '@fontsource/poppins/900.css'
import '@fontsource/poppins/800.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/600.css'


function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER,
    }

    TagManager.initialize(tagManagerArgs)
  }, [])

  return (
    <>
      <RouterProvider router={router} />
      <ErrorModal />
    </>
  )
}

export default App
