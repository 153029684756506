
const AgentBranchesTableHeader = () => {
  return (
    <thead>
      <tr>
        <th id="branch" scope="col">Branch</th>
        <th id="city" scope="col">City</th>
        <th id="address" scope="col">Address</th>
        {/* <th id="opening-hours" scope="col">Opening hours</th> */}
        <th id="select-branch" scope="col"></th>
      </tr>
    </thead>
  )
}

export default AgentBranchesTableHeader
