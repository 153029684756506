import { AgentBranchesDataItem } from '@customTypes/common'
import { memo, useCallback } from 'react'

type AgentBranchesTableRowProps = {
  tableDataItem: AgentBranchesDataItem
  onOpenModal: (currentSelectedBranch: AgentBranchesDataItem) => void
}

const AgentBranchesTableRow = ({ tableDataItem, onOpenModal }: AgentBranchesTableRowProps) => {

  const onClickSelect = useCallback(() => {
    onOpenModal(tableDataItem)
  }, [onOpenModal, tableDataItem])

  return (
    <tr>
      <td headers="branch" data-title="Branch">
        {tableDataItem.branch}
      </td>
      <td headers="city" data-title="City">
        {tableDataItem.city}
      </td>
      <td headers="address" data-title="Address">
        {tableDataItem.address}
      </td>
      {/* <td headers="opening-hours" data-title="Opening hours">
        {tableDataItem.openingHours}
      </td> */}
      <td headers="select-branch" data-title="Select branch">
        <button
          className="btn btn-primary"
          data-branch-name={`${tableDataItem.branch}`}
          onClick={onClickSelect}
        >
          Select
        </button>
        {/* {tableDataItem.openingHours} */}
      </td>
    </tr>
  )
}

export default memo(AgentBranchesTableRow)
