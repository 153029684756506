import { Tx, TxDetailsForList } from '@customTypes/transaction'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import { LegacyRef } from 'react'
import { PuffLoader } from 'react-spinners'

type TransactionsTable = {
  tableData: Array<TxDetailsForList>
  sentryRef: LegacyRef<HTMLTableRowElement>
  rootRef: LegacyRef<HTMLDivElement>
  loading: boolean
  hasNextPage: boolean
  onClickRow: (id: number) => void
}

const TransactionsTable = ({
  tableData,
  sentryRef,
  rootRef,
  loading,
  hasNextPage,
  onClickRow,
}: TransactionsTable) => {
  return (
    <div className="mz-table tx-table table-scroll" ref={rootRef}>
      <table>
        <TableHeader />
        <tbody>
          {!!tableData.length && tableData.map(tableDataItem => (
            <TableRow key={tableDataItem.id} tableDataItem={tableDataItem} onClickRow={onClickRow}/>
          ))}
          {(loading || hasNextPage) && <tr className="" ref={sentryRef}></tr>}
        </tbody>
      </table>

      {!tableData.length && loading &&<div className="empty-container">
        <div className="spinner">
          <PuffLoader size={70} color="#3171D8" />
        </div>
      </div>}

      {!tableData.length && !loading && <div className="empty-container">No Entries</div>}
    </div>
  )
}

export default TransactionsTable
