import Icon from '@components/Icon'
import { useCallback, useRef, useState } from 'react'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import imageCompression from 'browser-image-compression'

type FormFileInputProps<T extends FieldValues> = UseControllerProps<T> & {
  placeholder?: string
  label?: string
}

const FormFileInput = <T extends FieldValues>({ name, control }: FormFileInputProps<T>) => {
  const [ localInputError, setLocalInputError ] = useState('')

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const {
    field: { onChange, value },
    fieldState: { error: inputError },
  } = useController({
    name,
    control,
  })

  const onUploadFile = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  const onChangeFileInput = useCallback(
    async(event: any) => {
      event.preventDefault()

      if(event.target.files[0] && event.target.files[0].size > 10485760) {
        setLocalInputError('File size must be less than 10Mb')
        if(fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        return
      } else {
        setLocalInputError('')
      }

      if(event.target.files[0] && event.target.files[0].size > 2097152) {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1024,
          useWebWorker: true
        }
  
        const compressedBlobFile = await imageCompression(event.target.files[0], options)

        const compressedFile = new File([compressedBlobFile], compressedBlobFile.name, {
          type: compressedBlobFile.type,
          lastModified: new Date().getTime(),
      })

      onChange(compressedFile)
      return
      }

      onChange(event.target.files[0])
    },
    [onChange]
  )

  const onDeleteFile = useCallback(() => {
    onChange(null)
    if(fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    setLocalInputError('')
    
  }, [onChange])

  return (
    <div>
      <div className="form-control-label">Add a document</div>
      <div className="d-flex align-items-center gap-12">
        <button className="transparent-button d-flex" onClick={onUploadFile} type="button">
          {inputError?.message && <Icon name="file" size={24} color="#991B1B" />}
          {!inputError?.message &&
            (value?.name ? (
              <Icon name="file-check" size={24} color="#324EEF" />
            ) : (
              <Icon name="file" size={24} />
            ))}
        </button>

        <div className="d-flex flex-col" onClick={onUploadFile} style={{ cursor: 'pointer' }}>
          <span
            className="f-monrope-sm fw-600"
            {...((inputError?.message || localInputError) && { style: { color: '#991B1B' } })}
          >
            {!inputError?.message && !localInputError && (value?.name ? ` ${value?.name}` : 'Upload file')}

            {inputError?.message && inputError?.message}
            {localInputError && localInputError}

          </span>
          <span className="form-control-hint">PDF, JPG, JPEG, PNG, less than 10MB</span>
        </div>

        {value?.name && (
          <button
            className="transparent-button d-flex ml-auto"
            type="button"
            onClick={onDeleteFile}
          >
            <Icon name="trash" size={16} color="#991B1B" />
          </button>
        )}
        <input
          hidden
          type="file"
          ref={fileInputRef}
          onChange={onChangeFileInput}
          // accept=".png, .jpg, .jpeg, .pdf"
          accept="image/png, image/jpeg, image/jpg, application/pdf"
          capture="environment"
        />
      </div>
    </div>
  )
}

export default FormFileInput
