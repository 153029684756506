import { Tx, TxDetailsForList } from '@customTypes/transaction'
import dateUtils from '@utils/date'
import transactionUtils from '@utils/transaction'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

type TableRowProps = {
  tableDataItem: TxDetailsForList
  onClickRow: (id: number) => void
}

const TableRow = ({ tableDataItem, onClickRow }: TableRowProps) => {
  // const navigate = useNavigate()

  const onClick = useCallback(() => {
    onClickRow(tableDataItem.id)
    // navigate(`/transactions/${tableDataItem.id}`)
  }, [onClickRow, tableDataItem.id])

  return (
    <tr onClick={onClick}>
      <td headers="id" data-title="Id">
        {tableDataItem.id}
      </td>
      <td headers="date" data-title="Date/Time">
        {dateUtils.getFormattedDate(tableDataItem.creationDate, 'dd/MM/yyyy HH:mm')}
      </td>
      <td headers="sender" data-title="Sender">
        {tableDataItem?.sender
          ? `${tableDataItem.sender.firstName} ${tableDataItem.sender.lastName}`
          : ''}
      </td>
      <td className="fw-600" headers="source-amount" data-title="Source Amount">
        {tableDataItem.sourceAmount} {tableDataItem.sourceCurrency}
      </td>
      <td className="fw-600" headers="destination-amoun" data-title="Destination Amount">
        {tableDataItem.destinationAmount} {tableDataItem.destinationCurrency}
      </td>
      <td headers="status" data-title="Status">
        {transactionUtils.getTxStatus(tableDataItem.status) && (
          <div className={`badge ${transactionUtils.getTxStatusClass(tableDataItem.status)}`}>
            {transactionUtils.getTxStatus(tableDataItem.status)}
          </div>
        )}
      </td>
    </tr>
  )
}

export default TableRow
