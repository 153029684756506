import * as yup from 'yup'


const emailFormScheme = yup.object({
  email: yup
    .string().trim()
    .typeError('Required field')
    .required('Required field')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Email is invalid'
    )
})

const signInFormScheme = yup.object({
  email: yup
    .string().trim()
    .typeError('Required field')
    .required('Required field')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Email is invalid'
    ),
  password: yup
    .string().trim()
    .typeError('Required field')
    .required('Required field')
})

const signUpFormScheme = yup.object({
  email: yup
    .string().trim()
    .typeError('Required field')
    .required('Required field')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Email is invalid'
    ),
  password: yup
    .string().trim()
    .typeError('Required field')
    .required('Required field')
    .matches(
      /^(?=.*?[A-Z])(?!.*\s)(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      // /^(?=.*\d)(?!.*\s)(?=.*[a-zA-Z]).{8,}$/,
      'Password is invalid'
    ),
    confirmPassword: yup
    .string()
    .typeError('Required field')
    .oneOf([yup.ref('password')], "Passwords don't match")
    .required('Required field'),

  firstName: yup
    .string().trim()
    .typeError('Required field')
    .required('Required field')
    .matches(
      /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
      'Please, use letters only'
    ),
  lastName: yup
    .string().trim()
    .typeError('Required field')
    .required('Required field')
    .matches(
      /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
      'Please, use letters only'
    ),
})



const loginYupSchemes = {
  emailFormScheme,
  signInFormScheme,
  signUpFormScheme
}

export default loginYupSchemes