import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { transactionActionCreators } from '@actionCreators/transactionActionCreators'
import Icon from '@components/Icon'
import DateRange from '@components/transactions/DateRange'
import SearchInput from '@components/transactions/SearchInput'
import TransactionsTable from '@components/transactions/TransactionsTable'
import StatusSelector from '@components/transactions/statusSelector/StatusSelector'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import commonUtils from '@utils/common'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useNavigate, useSearchParams } from 'react-router-dom'
import transactionUtils from '@utils/transaction'
import dateUtils from '@utils/date'
import { debounce } from 'lodash'

const TransactionsPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [queryParams, setQueryParams] = useSearchParams()
  const [queryList, setQueryList] = useState({
    search: '',
    status: '',
    dateFrom: '',
    dateTo: '',
  })

  const { txList, getTransactionDetailsByIdLoadState, metaForGetTransactionList, error } =
    useAppSelector(state => state.transaction)

  const isFirstInitializeRef = useRef(false)

  const hasNextPage = useMemo(() => {
    if (!metaForGetTransactionList) {
      return false
    }

    return metaForGetTransactionList?.totalPages - metaForGetTransactionList?.currentPage > 0
  }, [metaForGetTransactionList])

  useEffect(() => {
    if (isFirstInitializeRef.current) {
      return
    }

    const searchParamValue = queryParams.get('search') && queryParams.get('search')?.trim()
    const statusParamValue = queryParams.get('status') && queryParams.get('status')?.trim()
    const dateFromParamValue = queryParams.get('dateFrom') && queryParams.get('dateFrom')?.trim()
    const dateToParamValue = queryParams.get('dateTo') && queryParams.get('dateTo')?.trim()
    let currentQueryList = {
      search: '',
      status: '',
      dateFrom: '',
      dateTo: '',
    }

    if (searchParamValue) {
      currentQueryList = {
        ...currentQueryList,
        ...{ search: decodeURIComponent(searchParamValue) },
      }
    }

    if (statusParamValue) {
      currentQueryList = { ...currentQueryList, ...{ status: statusParamValue } }
    }

    if (dateFromParamValue) {
      currentQueryList = { ...currentQueryList, ...{ dateFrom: dateFromParamValue } }
    }

    if (dateToParamValue) {
      currentQueryList = { ...currentQueryList, ...{ dateTo: dateToParamValue } }
    }

    setQueryList(currentQueryList)

    const currentQueryListWithoutEmptyFields = pickBy(currentQueryList, identity)
    const page = 1
    const limit = 10

    dispatch(
      transactionActionCreators.getTransactionList({
        page,
        limit,
        query: currentQueryListWithoutEmptyFields,
      })
    )
    isFirstInitializeRef.current = true
  }, [dispatch, queryParams])

  const getTxListAfterChangeSearch = useCallback(
    (value: string) => {
      const currentPage = 1
      const limit = 10
      const modifiedQueryList = { ...queryList, ...{ search: value } }
      const modifiedQueryParams = commonUtils.trimObjectFields(modifiedQueryList) as Record<
        string,
        string
      >
      const modifiedQueryParamsWithoutEmptyFields = pickBy(modifiedQueryParams, identity)

      dispatch(
        transactionActionCreators.getTransactionList({
          page: currentPage,
          limit,
          query: modifiedQueryParamsWithoutEmptyFields,
        })
      )

      setQueryParams({ search: encodeURIComponent(value) })

      setQueryList(modifiedQueryList)
    },
    [dispatch, queryList, setQueryParams]
  )

  const debounceFunction = useMemo(
    () => debounce(getTxListAfterChangeSearch, 700),
    [getTxListAfterChangeSearch]
  )

  const onChangeSearch = useCallback(
    (value: string) => {
      debounceFunction(value)
    },
    [debounceFunction]
  )

  const onChangeStatus = useCallback(
    (value: string) => {
      const currentPage = 1
      const limit = 10
      const modifiedQueryList = { ...queryList, ...{ status: value } }
      const modifiedQueryParams = commonUtils.trimObjectFields(modifiedQueryList) as Record<
        string,
        string
      >
      const modifiedQueryParamsWithoutEmptyFields = pickBy(modifiedQueryParams, identity)

      dispatch(
        transactionActionCreators.getTransactionList({
          page: currentPage,
          limit,
          query: modifiedQueryParamsWithoutEmptyFields,
        })
      )

      const searchParam = encodeURIComponent(modifiedQueryList.search)

      setQueryParams({ ...modifiedQueryList, ...{ search: searchParam } })

      setQueryList(modifiedQueryList)
    },
    [dispatch, queryList, setQueryParams]
  )

  const onDateRangeChange = useCallback(
    (values: Array<Date | null>) => {
      const modifiedValue = values.map(value =>
        value === null ? '' : dateUtils.getFormattedDate(value, 'yyyy-MM-dd')
      )
      const currentPage = 1
      const limit = 10
      const modifiedQueryList = {
        ...queryList,
        ...{ dateFrom: modifiedValue[0], dateTo: modifiedValue[1] },
      }
      const modifiedQueryParams = commonUtils.trimObjectFields(modifiedQueryList) as Record<
        string,
        string
      >
      const modifiedQueryParamsWithoutEmptyFields = pickBy(modifiedQueryParams, identity)

      dispatch(
        transactionActionCreators.getTransactionList({
          page: currentPage,
          limit,
          query: modifiedQueryParamsWithoutEmptyFields,
        })
      )

      if (modifiedQueryParamsWithoutEmptyFields.search) {
        setQueryParams({
          ...modifiedQueryParamsWithoutEmptyFields,
          ...{ search: encodeURIComponent(modifiedQueryParamsWithoutEmptyFields.search) },
        })
      } else {
        setQueryParams(modifiedQueryParamsWithoutEmptyFields)
      }

      setQueryList(modifiedQueryList)
    },
    [dispatch, queryList, setQueryParams]
  )

  const onLoadMore = async () => {
    const currentPage = metaForGetTransactionList?.currentPage
      ? metaForGetTransactionList?.currentPage + 1
      : 1
    const limit = 10
    const queryListWithoutEmptyFields = pickBy(queryList, identity)

    dispatch(
      transactionActionCreators.getTransactionList({
        page: currentPage,
        limit,
        query: queryListWithoutEmptyFields,
      })
    )
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: getTransactionDetailsByIdLoadState.isLoading,
    hasNextPage,
    onLoadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  })

  const onClickRow = useCallback((id: number) =>{
    navigate(`/transactions/${id}`)
  },[navigate])

  return (
    <section>
      <div className="main-padding-container">
        <h3 className="t-center mb-40">Transactions</h3>
        <div className="background-container">
          <div className="box is-transparent">
            <div className="filters mb-24">
              <div className="filters-row">
                <div className="filters-group align-right">
                  <div className="form-group">
                    <SearchInput value={queryList.search} onParentChange={onChangeSearch} />
                  </div>
                </div>
              </div>
              <div className="filters-row">
                <div className="filters-group align-right">
                  <StatusSelector
                    options={transactionUtils.txStatusOptions}
                    onParentChange={onChangeStatus}
                    value={queryList.status}
                  />

                  <DateRange
                    dateFrom={queryList.dateFrom}
                    dateTo={queryList.dateTo}
                    onDateRangeChange={onDateRangeChange}
                  />
                  {/* <button type="button" className="btn btn-secondary is-small">
                    Download Report
                  </button> */}
                </div>
              </div>
            </div>
            <TransactionsTable
              tableData={txList}
              onClickRow={onClickRow}
              sentryRef={sentryRef}
              rootRef={rootRef}
              loading={getTransactionDetailsByIdLoadState.isLoading}
              hasNextPage={hasNextPage}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TransactionsPage
