import { Payer } from "@customTypes/calculation"
import uniq from "lodash/uniq"

const checkForUnfilledFields = (data: Record<string, string | number>) => {
  for (const key in data) {
    if (data[key]) {
      continue
    } else {
      return false
    }
  }

  return true
}

const concatArrayWithUniqValues = (firstArray: Array<string>, secondArray: Array<string>) => {
  return uniq([...firstArray, ...secondArray])
}

const getNewTxDetailsFromFieldsConfig = (payers: Array<Payer>, payerId: number) => {
  const payer = payers.find(payerItem => payerItem.id === payerId)


  // service id with value 2 is the 'BankAccount' service
  if (payer?.country_iso_code === 'GBR' && payer.currency === 'GBP' && payer.service.id === 2) {
    return {
      creditPartyIdentifier: ['account_number', 'sort_code'],
      sender: concatArrayWithUniqValues(payer?.transaction_types.C2C.required_sending_entity_fields[0] || [], ['firstname', 'lastname', 'id_number']),
      beneficiary: concatArrayWithUniqValues(payer?.transaction_types.C2C.required_receiving_entity_fields[0] || [], ['firstname', 'lastname']),
    }
  }

  return {
    creditPartyIdentifier: payer?.transaction_types.C2C.credit_party_identifiers_accepted[0] || [],
    sender: concatArrayWithUniqValues(payer?.transaction_types.C2C.required_sending_entity_fields[0] || [], ['firstname', 'lastname', 'id_number']),
    beneficiary: concatArrayWithUniqValues(payer?.transaction_types.C2C.required_receiving_entity_fields[0] || [], ['firstname', 'lastname']),
  }
}


const calculationUtils = {
  checkForUnfilledFields,
  getNewTxDetailsFromFieldsConfig,
}

export default calculationUtils