import authActionCreators from '@actionCreators/authActionCreators'
import { useAppDispatch } from '@hooks/reduxHooks'
import { User } from 'firebase/auth'
import { useCallback, useEffect } from 'react'
import { auth } from '@fire-base/mainFirebase'
import { Outlet, useNavigate } from 'react-router-dom'

const FirebaseLayout = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const authorizeUser = useCallback(
    async (user: User) => {
      const userFirebaseAccessToken = await user.getIdToken(false)
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const body = {
        providerId: user.providerData[0].providerId,
        accessToken: userFirebaseAccessToken,
        timezone: userTimeZone,
      }

      // console.log(userFirebaseAccessToken)

      dispatch(authActionCreators.authorizeUser({ body, navigate }))
    },
    [dispatch, navigate]
  )

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      // console.log(user)
      if (user) {
        authorizeUser(user)
      } else {
      }
    })

    return () => unsubscribe()
  }, [authorizeUser, dispatch])
  return <Outlet />
}

export default FirebaseLayout
