import { CalculateMode } from '@customTypes/calculation'
import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react'
import CurrencyInput, { CurrencyInputOnChangeValues } from 'react-currency-input-field'
import { BeatLoader } from 'react-spinners'

type CalculationInputProps = {
  name: string
  value: string | number
  label: string
  mode: CalculateMode
  onParentChange: (value: any, name: string, mode: CalculateMode) => void
  isLoading: boolean
}

const CalculationInput = ({
  name,
  value,
  label,
  onParentChange,
  mode,
  isLoading,
}: CalculationInputProps) => {
  const onChange = useCallback(
    (
      value: string | undefined,
      name?: string | undefined,
      values?: CurrencyInputOnChangeValues | undefined
    ) => {
      const inputName = name || ''

      onParentChange(value, inputName, mode)
    },
    [mode, onParentChange]
  )

  return (
    <div className="input-col">
      <label>{label}</label>
      {isLoading ? (
        <div className="loader-wrap">
          <BeatLoader size={20} color="#324EEF" />
        </div>
      ) : (
        <CurrencyInput
          name={name}
          className=""
          // placeholder="Please enter a number"
          value={value}
          decimalsLimit={2}
          onValueChange={onChange}
          allowDecimals={true}
          maxLength={10}
          formatValueOnBlur={false}
          decimalSeparator="."
          groupSeparator=" " 
        />
      )}
    </div>
  )
}

export default memo(CalculationInput)
