import React from 'react'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { transactionActionCreators } from '@actionCreators/transactionActionCreators'
import Icon from '@components/Icon'
import DateRange from '@components/transactions/DateRange'
import SearchInput from '@components/transactions/SearchInput'
import TransactionsTable from '@components/transactions/TransactionsTable'
import StatusSelector from '@components/transactions/statusSelector/StatusSelector'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import commonUtils from '@utils/common'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useNavigate, useSearchParams } from 'react-router-dom'
import transactionUtils from '@utils/transaction'
import dateUtils from '@utils/date'
import { debounce } from 'lodash'
import Switcher from '@components/calculation/ui/Switcher'

const isBoolean = (value: string) => {
  return value.toLowerCase() === 'true' || value.toLowerCase() === 'false'
}

const CompletedTransactionsPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [queryParams, setQueryParams] = useSearchParams()
  const [queryList, setQueryList] = useState({
    search: '',
    all: false,
    // status: '',
  })

  const { txList, getTransactionDetailsByIdLoadState, metaForGetTransactionList, error } =
    useAppSelector(state => state.transaction)

  const isFirstInitializeRef = useRef(false)

  const hasNextPage = useMemo(() => {
    if (!metaForGetTransactionList) {
      return false
    }

    return metaForGetTransactionList?.totalPages - metaForGetTransactionList?.currentPage > 0
  }, [metaForGetTransactionList])

  useEffect(() => {
    if (isFirstInitializeRef.current) {
      return
    }

    const searchParamValue = queryParams.get('search') && queryParams.get('search')?.trim()
    const allTransactionsParamValue = queryParams.get('all') && queryParams.get('all')?.trim()

    let currentQueryList = {
      search: '',
      all: false,
    }

    if (searchParamValue) {
      currentQueryList = {
        ...currentQueryList,
        ...{ search: decodeURIComponent(searchParamValue) },
      }
    }

    if (allTransactionsParamValue && isBoolean(allTransactionsParamValue)) {
      // currentQueryList = { ...currentQueryList, ...{ all: Boolean(allTransactionsParamValue) } }
    }

    setQueryList(currentQueryList)

    const currentQueryListWithoutEmptyFields = pickBy(currentQueryList, identity)
    // const currentQueryListWithoutEmptyFields = pickBy({ search: currentQueryList.search }, identity)
    const page = 1
    const limit = 10

    dispatch(
      transactionActionCreators.getCashOutTransactionList({
        page,
        limit,
        query: currentQueryListWithoutEmptyFields,
      })
    )
    isFirstInitializeRef.current = true
  }, [dispatch, queryParams])

  const getTxListAfterChangeSearch = useCallback(
    (value: string) => {
      const currentPage = 1
      const limit = 10
      const modifiedQueryList = { ...queryList, ...{ search: value } }
      const modifiedQueryParams = commonUtils.trimObjectFields(modifiedQueryList)
      const modifiedQueryParamsWithoutEmptyFields = pickBy(modifiedQueryParams, identity)

      dispatch(
        transactionActionCreators.getCashOutTransactionList({
          page: currentPage,
          limit,
          query: modifiedQueryParamsWithoutEmptyFields,
        })
      )

      setQueryParams({ search: encodeURIComponent(value) })

      setQueryList(modifiedQueryList)
    },
    [dispatch, queryList, setQueryParams]
  )

  const debounceFunction = useMemo(
    () => debounce(getTxListAfterChangeSearch, 700),
    [getTxListAfterChangeSearch]
  )

  const onChangeSearch = useCallback(
    (value: string) => {
      debounceFunction(value)
    },
    [debounceFunction]
  )

  const getTxListAfterChangeAllTxSwitcher = useCallback(
    (value: boolean) => {
      const currentPage = 1
      const limit = 10
      const modifiedQueryList = { ...queryList, ...{ all: value } }
      const modifiedQueryParams = commonUtils.trimObjectFields(modifiedQueryList)
      const modifiedQueryParamsWithoutEmptyFields = pickBy(modifiedQueryParams, identity)

      dispatch(
        transactionActionCreators.getCashOutTransactionList({
          page: currentPage,
          limit,
          query: modifiedQueryParamsWithoutEmptyFields,
        })
      )

      setQueryParams({ all: String(value) })

      setQueryList(modifiedQueryList)
    },
    [dispatch, queryList, setQueryParams]
  )

  const debounceSwitcherFunction = useMemo(
    () => debounce(getTxListAfterChangeAllTxSwitcher, 700),
    [getTxListAfterChangeAllTxSwitcher]
  )

  const onChangeSwitcher = useCallback(
    (value: boolean) => {
      debounceSwitcherFunction(value)
    },
    [debounceSwitcherFunction]
  )

  const onLoadMore = async () => {
    const currentPage = metaForGetTransactionList?.currentPage
      ? metaForGetTransactionList?.currentPage + 1
      : 1
    const limit = 10
    const queryListWithoutEmptyFields = pickBy(queryList, identity)

    dispatch(
      transactionActionCreators.getCashOutTransactionList({
        page: currentPage,
        limit,
        query: queryListWithoutEmptyFields,
      })
    )
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: getTransactionDetailsByIdLoadState.isLoading,
    hasNextPage,
    onLoadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  })

  const onClickRow = useCallback((id: number) =>{
    navigate(`/agent/cash-outs/completed-transactions/${id}`)
  },[navigate])

  return (
    <section>
      <div className="main-padding-container">
        <h3 className="t-center mb-40">Completed Transactions</h3>
        <div className="background-container">
          <div className="box is-transparent">
            <div className="filters mb-24">
              <div className="filters-row">
                <div className="filters-group align-right">
                  <div className="form-group">
                    <SearchInput value={queryList.search} onParentChange={onChangeSearch} />
                  </div>
                </div>
              </div>
              <div className="filters-row">
                <div className="filters-group align-right">
                  <div className='d-flex align-items-center justify-content-center gap-8' style={{fontSize: '14px'}}>Your transactions<Switcher
                    isParentChecked={queryList.all}
                    onChangeParentSwitcher={onChangeSwitcher}
                  />All transactions</div>
                  
                  {/* <StatusSelector
                    options={transactionUtils.txStatusOptions}
                    onParentChange={onChangeStatus}
                    value={queryList.status}
                  /> */}

                  {/* <button type="button" className="btn btn-secondary is-small">
                    Download Report
                  </button> */}
                </div>
              </div>
            </div>
            <TransactionsTable
              tableData={txList}
              onClickRow={onClickRow}
              sentryRef={sentryRef}
              rootRef={rootRef}
              loading={getTransactionDetailsByIdLoadState.isLoading}
              hasNextPage={hasNextPage}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompletedTransactionsPage
