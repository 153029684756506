import Icon from '@components/Icon'
import { Dispatch, ReactNode, SetStateAction, useCallback } from 'react'
import ReactModal from 'react-modal'

type DialogModalProps = {
  confirmFunction: () => void
  cancelFunction: () => void
  isOpenModal: boolean
  titleText: string
  bodyComponent: ReactNode
  confirmBtnText: 'Confirm'
  cancelBtnText: 'Cancel'
}

const DialogModal = ({
  isOpenModal,
  confirmFunction,
  cancelFunction,
  titleText,
  bodyComponent,
  confirmBtnText,
  cancelBtnText,
}: DialogModalProps) => {

  return (
    <ReactModal
      onRequestClose={cancelFunction}
      shouldCloseOnOverlayClick={true}
      className=""
      overlayClassName="dialog-overlay"
      isOpen={isOpenModal}
    >
      <>
        <div className="dialog medium">
          <button className="dialog-close" onClick={cancelFunction}>
            <Icon name="close" size={24} />
          </button>
          <div className="dialog-title">{titleText}</div>
          <div className="dialog-body">{bodyComponent}</div>
          <div className="dialog-footer button-group gapless flex-wrap">
            <button type="button" className="btn btn-tertiary" onClick={cancelFunction}>
              {cancelBtnText}
            </button>

            <button type="button" className="btn btn-primary confirm-button" onClick={confirmFunction}>
              {confirmBtnText}
            </button>
          </div>
        </div>
      </>
    </ReactModal>
  )
}

export default DialogModal
