import Home from '@pages/Home'
import NewTx from '@pages/NewTx'
import TransactionDetailsPage from '@pages/TransactionDetailsPage'
import TransactionsPage from '@pages/TransactionsPage'
import Layout from '@structureComponents/Layout'

import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import ProtectedSignInRoute from './ProtectedSignInRoute'
import LoginPage from '@pages/LoginPage'
import AgentProtectedRoute from './AgentProtectedRoute'
import FirebaseLayout from './../FirebaseLayout'
import AgentBranches from '@pages/AgentBranches'
import CashOutLookUpPage from '@pages/CashOutLookUpPage'
import CompletedTransactionsPage from '@pages/CompletedTransactionsPage'
import CompletedTransactionDetailsPage from '@pages/CompletedTransactionDetatilsPage'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<FirebaseLayout />}>
      {/* <Route index element={<Navigate to="/" />} /> */}
      <Route element={<Layout />}>
        <Route index element={<Home />} />
        <Route element={<ProtectedSignInRoute />}>
          <Route path="login" element={<LoginPage />} />
        </Route>

        <Route element={<AgentProtectedRoute />}>
          <Route path="agent/new-transaction" element={<NewTx />} />
          <Route path="transactions" element={<TransactionsPage />} />
          <Route path="transactions/:id" element={<TransactionDetailsPage />} />
          <Route path="agent/cash-outs/look-up" element={<CashOutLookUpPage />} />
          <Route path='agent/cash-outs/completed-transactions' element={<CompletedTransactionsPage />}/>
          <Route path='agent/cash-outs/completed-transactions/:id' element={<CompletedTransactionDetailsPage />}/>
        </Route>
        <Route path="agent-branches" element={<AgentBranches />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />

      {/* <Route index element={<Navigate to="/events" />} />
      <Route element={<Layout />}>

        <Route element={<ProtectedSignInRoute />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="events/:id/video/:videoId" element={<VideoPlayerPage />} />
        </Route>

        <Route path="agents" element={<AgentPage />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Route> */}

      {/* <Route element={<LayoutV2 />}>
        <Route path="events" element={<EventListPage />} />
        <Route path="events/:id" element={<EventDetailsPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="events/:id/payment" element={<EventPaymentScreen />} />
          <Route path="events/successful-payment" element={<EventSuccessfulPaymentPage />} />
        </Route>

        <Route element={<AgentProtectedRoute />}>
          <Route path="agent" element={<AgentEventListPage />} />
          <Route path="agent/create-order/:id" element={<AgentCreateOrderPage />} />
        </Route>
      </Route> */}
    </Route>
  )
)

export default router
