import { FileType, MetaForList } from "./common"

export type Tx = {
  id: number
  date: string
  sender: string
  srcAmount: string
  destAmount: string
  status: string
}

export type CreateTxRequestBody = NewTxDetailsFormType & {
  quotationId: number
}

export type TxDetailsForList = {
  id: number
  creationDate: string
  destinationAmount: string
  destinationCurrency: string

  sender: {
    id: number,
    firstName: string,
    lastName: string,
  }
  sourceAmount: string
  sourceCurrency: "USD"
  status: TransactionStatus
}

export type GetTransactionListResponseBody = {
  data: Array<TxDetailsForList>
  meta: MetaForList
}

export type GetTransactionListRequestArg = {
  page: number
  limit: number
  query?: Record<string, string>
}

export type GetCompletedTransactionListRequestArg = {
  page: number
  limit: number
  query?: Record<string, string | boolean>
}

export enum TransactionStatus {
  INITIATED = 10,
  CREATED = 15,
  PAYOUT_PROCESSING = 20,
  COMPLETED = 30,
  FAILED = 40,
  EXPIRED = 50,
  ON_HOLD = 60,
  REFUNDED = 70,
  CANCELED = 80,
  REVERSED = 90
}

export type TxDetails = {
  id: number,
  creationDate: string,
  source: {
    amount: number,
    country_iso_code: string,
    currency: string
  },
  destination: {
    amount: number,
    currency: string
  },
  status: TransactionStatus,
  payer: {
    name: string,
    service: {
      name: string
    }

  },
  totalFee: number
  discount: { amount: number, currency: string }
  sender: SenderForNewTxDetailsForm
  beneficiary: BeneficiaryForNewTxDetailsForm
  creditPartyIdentifier: CreditPartyIdentifierForNewTxDetailsForm
  purposeOfRemittance: string
  reference: string
  totalAmount: number
}

export type SenderForNewTxDetailsForm = {
  firstname: string
  lastname: string
  id_number: string
  id_type?: string
  // code?: string
  date_of_birth?: string
  country_of_birth_iso_code?: string
  address?: string
  city?: string
  country_iso_code?: string
  nationality_country_iso_code?: string
  email?: string
  msisdn?: string
  postal_code?: string
  province_state?: string
}

export type BeneficiaryForNewTxDetailsForm = {
  firstname: string
  lastname: string
  code?: string
  id_type?: string
  id_number?: string
  date_of_birth?: string
  country_of_birth_iso_code?: string
  country_iso_code?: string
  address?: string
  city?: string
  nationality_country_iso_code?: string
  msisdn?: string
  email?: string
  postal_code?: string
  province_state?: string
}

export type CreditPartyIdentifierForNewTxDetailsForm = {
  msisdn?: string
  iban?: string
  swift_bic_code?: string
  bank_account_number?: string
  ifs_code?: string
  account_number?: string
  sort_code?: string
  bsb_number?: string
  email?: string
  routing_code?: string
}

export type NewTxDetailsFormType = {
  creditPartyIdentifier: CreditPartyIdentifierForNewTxDetailsForm
  sender: SenderForNewTxDetailsForm
  beneficiary: BeneficiaryForNewTxDetailsForm
  purposeOfRemittance: string
  file?: FileType
  reference?: string
  promoCode?: string
}

export type TxLog = {
  createdAt: string
  externalTransactionData: Record<string, string | number>
  externalTransactionId: string
  externalTransactionStatus: string
  id: number
  initiatorName: string
  poTransactionId: number
}


export type CheckPromoCodeResponse = {
  isAvailable: boolean,
  promoCodeId: number
}

export type CompleteCashOutTransactionBody = {
  id: number
}
export type BookCashOutTransactionBody = CompleteCashOutTransactionBody