import userServices from "@api/services/userServices"
import { CustomErrorResponse } from "@customTypes/common"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { NavigateFunction } from "react-router-dom"
import { RootState } from ".."

const getProfile = createAsyncThunk(
  'user/getProfile', async (navigate: NavigateFunction, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState
    const isLoginCurrentSession = state.auth.isLoginCurrentSession
    try {
      const res = await userServices.getProfile()

      if(res.userRole === 'Agent' && isLoginCurrentSession) {
        navigate('/agent/new-transaction')
      }

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)

const userActionCreators = {
  getProfile
}

export default userActionCreators