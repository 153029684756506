import { BookCashOutTransactionBody, CheckPromoCodeResponse, CompleteCashOutTransactionBody, CreateTxRequestBody, GetTransactionListResponseBody, TxDetails } from "@customTypes/transaction"
import { anonymousRequest } from "./anonymousRequest"
import { authorizedRequest } from "@api/authorizeRequest"

const createTx = (body: FormData) => {
  const path = ['money-transfer', 'transactions-file']
  const contentType = 'multipart/form-data'

  return authorizedRequest<FormData, null>('v1', path, "POST", body, null, contentType)
}

const getTransactionList = (page: number, limit: number, queryParams?: Record<string, string>) => {
  const path = ['money-transfer', 'transactions']
  const query = {
    page,
    limit,
    ...queryParams,
  }


  return authorizedRequest<null, GetTransactionListResponseBody>('v1', path, "GET", null, query)
}

const getTransactionDetailsById = (id: string) => {
  const path = ['money-transfer', 'transactions', id]


  return authorizedRequest<null, TxDetails>('v1', path, "GET")
}

const getTransactionLogsById = (id: string) => {
  const path = ['money-transfer', 'transactions', id, 'logs']


  return authorizedRequest<null, any>('v1', path, "GET")
}

const checkPromoCode = (promocode: string) => {
  const path = ['money-transfer', 'promo-codes', promocode, 'check']


  return authorizedRequest<null, CheckPromoCodeResponse>('v1', path, "GET")
}

const lookUpTransaction = (searchValue: string) => {
  const path = ['money-transfer', 'cash-payouts', 'lookup']
  const query = {
    code: searchValue
  }

  return authorizedRequest<null, TxDetails>('v1', path, "GET", null, query)
}

const getCashOutTransactionById = (id: string) => {
  const path = ['money-transfer', 'cash-payouts', 'transactions', `${id}`]


  return authorizedRequest<null, TxDetails>('v1', path, "GET")
}

const getCashOutTransactionList = (page: number, limit: number, queryParams?: Record<string, string | boolean>) => {
  const path = ['money-transfer', 'cash-payouts', 'transactions']
  const query = {
    page,
    limit,
    ...queryParams,
  }


  return authorizedRequest<null, GetTransactionListResponseBody>('v1', path, "GET", null, query)
}

const completeCashOutTransaction = (body: CompleteCashOutTransactionBody) => {
  const path = ['money-transfer', 'cash-payouts', 'transactions', 'complete']

  return authorizedRequest<CompleteCashOutTransactionBody, null>('v1', path, "POST", body)
}

const bookCashOutTransaction = (body: BookCashOutTransactionBody) => {
  const path = ['money-transfer', 'cash-payouts', 'transactions', 'book']

  return authorizedRequest<BookCashOutTransactionBody, null>('v1', path, "POST", body)
}


export const transactionServices = {
  createTx,
  getTransactionList,
  getTransactionDetailsById,
  getTransactionLogsById,
  getCashOutTransactionList,
  checkPromoCode,
  lookUpTransaction,
  getCashOutTransactionById,
  completeCashOutTransaction,
  bookCashOutTransaction,
}