import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

type SwitcherProps = {
  isParentChecked: boolean
  onChangeParentSwitcher: (value: boolean) => void
}

const Switcher = ({ isParentChecked, onChangeParentSwitcher }: SwitcherProps) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    if (isParentChecked) {
      setIsChecked(isParentChecked)
    }
  }, [isParentChecked])

  const onChangeSwitcher = useCallback((event: ChangeEvent<HTMLInputElement>)=>{
    const value = event.target.checked

    setIsChecked(value)
    onChangeParentSwitcher(value)

  },[onChangeParentSwitcher])

  return (
    <label className="switch">
      <input type="checkbox" checked={isChecked} onChange={onChangeSwitcher}/>
      <span className="slider round"></span>
    </label>
  )
}

export default Switcher
