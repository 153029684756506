import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import FormInput from './FormInput'
import { useForm } from 'react-hook-form'
import FormPhoneNumberInput from './FormPhoneNumberInput'
import { yupResolver } from '@hookform/resolvers/yup'
import txYupScheme from '@utils/yupSchemes/txYupSchemes'
import FormFileInput from './FormFileInput'
import Icon from '@components/Icon'
import transactionUtils from '@utils/transaction'
import FormSelector from './CustomSelector/CustomSelector'
import { selectStyles } from './CustomSelector/styleConfig'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import calculationUtils from '@utils/calculation'
import CommonSenderFormField from './CommonSenderFormField'
import CommonBeneficiaryFormField from './CommonBeneficiaryFormField'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import CommonCreditPartyFormField from './CommonCreditPartyFormField'
import commonUtils from '@utils/common'
import {
  BeneficiaryForNewTxDetailsForm,
  CheckPromoCodeResponse,
  CreditPartyIdentifierForNewTxDetailsForm,
  NewTxDetailsFormType,
  SenderForNewTxDetailsForm,
} from '@customTypes/transaction'
import Test from './takingPhoto/Test'
import { transactionActionCreators } from '@actionCreators/transactionActionCreators'
import { CalculationFormDataType } from '@components/calculation/CalculationForm'
import { calculationActionCreators } from '@actionCreators/calculationActionCreators'
import { BeatLoader } from 'react-spinners'
import { CustomErrorResponse } from '@customTypes/common'

type NewTxDetailsFormProps = {
  onParentSubmit: (dataForm: NewTxDetailsFormType) => void
  txDetails: NewTxDetailsFormType | null
  isShownForm: boolean
  payerId?: number
  destinationCountryIso2Code: string
  sourceCountryIso2Code: string
  calcDetails: CalculationFormDataType | null
  setPromoCode: Dispatch<SetStateAction<string>>
}

const NewTxDetailsForm = ({
  onParentSubmit,
  txDetails,
  payerId,
  isShownForm,
  destinationCountryIso2Code,
  sourceCountryIso2Code,
  calcDetails,
  setPromoCode,
}: NewTxDetailsFormProps) => {
  const dispatch = useAppDispatch()

  const { payers, makeCalculationLoadState } = useAppSelector(state => state.calculation)
  const { checkPromoCodeLoadState } = useAppSelector(state => state.transaction)

  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [isValidPromoCode, setIsValidPromocode] = useState(false)
  const [promoCodeInputError, setPromoCodeInputError] = useState('')
  const [promoCodeInputValue, setPromoCodeInputValue] = useState('')

  useEffect(() => {
    if (txDetails?.promoCode) {
      setPromoCodeInputValue(txDetails?.promoCode)
      setIsValidPromocode(true)
    }
  }, [txDetails?.promoCode])

  const formFieldsConfig = useMemo(
    () => (payerId ? calculationUtils.getNewTxDetailsFromFieldsConfig(payers, payerId) : null),
    [payerId, payers]
  )

  const currentServiceId = useMemo(
    () => payers.find(payer => payer.id === payerId)?.service.id,
    [payerId, payers]
  )

  const isShownReferenceField = useMemo(() => {
    if (payerId) {
      // service id with value 2 is the 'BankAccount' service
      if (currentServiceId === 2) {
        return true
      }
    } else {
      return false
    }
  }, [currentServiceId, payerId])

  const { handleSubmit, formState, control, setValue, reset, watch } =
    useForm<NewTxDetailsFormType>({
      resolver: yupResolver(txYupScheme.newTxDetailsFormScheme(formFieldsConfig, currentServiceId)),
    })

  const setFormValue = useCallback(() => {
    setValue('purposeOfRemittance', txDetails?.purposeOfRemittance || '')
    setValue('reference', txDetails?.reference)

    setValue('creditPartyIdentifier.msisdn', txDetails?.creditPartyIdentifier.msisdn)
    setValue('creditPartyIdentifier.iban', txDetails?.creditPartyIdentifier.iban)
    setValue(
      'creditPartyIdentifier.swift_bic_code',
      txDetails?.creditPartyIdentifier.swift_bic_code
    )
    setValue(
      'creditPartyIdentifier.bank_account_number',
      txDetails?.creditPartyIdentifier.bank_account_number
    )
    setValue('creditPartyIdentifier.ifs_code', txDetails?.creditPartyIdentifier.ifs_code)
    setValue(
      'creditPartyIdentifier.account_number',
      txDetails?.creditPartyIdentifier.account_number
    )
    setValue('creditPartyIdentifier.sort_code', txDetails?.creditPartyIdentifier.sort_code)
    setValue('creditPartyIdentifier.bsb_number', txDetails?.creditPartyIdentifier.bsb_number)
    setValue('creditPartyIdentifier.email', txDetails?.creditPartyIdentifier.email)
    setValue('creditPartyIdentifier.routing_code', txDetails?.creditPartyIdentifier.routing_code)

    //sender
    setValue('sender.firstname', txDetails?.sender.firstname || '')
    setValue('sender.lastname', txDetails?.sender.lastname || '')
    setValue('sender.id_number', txDetails?.sender.id_number || '')
    setValue('sender.id_type', txDetails?.sender.id_type)
    setValue('sender.email', txDetails?.sender.email)
    setValue('sender.date_of_birth', txDetails?.sender.date_of_birth)
    setValue(
      'sender.country_of_birth_iso_code',
      txDetails?.sender.country_of_birth_iso_code || 'ZWE'
    )
    setValue('sender.nationality_country_iso_code', txDetails?.sender.nationality_country_iso_code)
    setValue('sender.address', txDetails?.sender.address)
    setValue('sender.city', txDetails?.sender.city)
    setValue('sender.country_iso_code', txDetails?.sender.country_iso_code)
    setValue('sender.address', txDetails?.sender.address)
    setValue('sender.msisdn', txDetails?.sender.msisdn)
    setValue('sender.postal_code', txDetails?.sender.postal_code)
    setValue('sender.province_state', txDetails?.sender.province_state)

    setValue('file', txDetails?.file)

    //benficiary
    setValue('beneficiary.firstname', txDetails?.beneficiary.firstname || '')
    setValue('beneficiary.lastname', txDetails?.beneficiary.lastname || '')
    setValue('beneficiary.id_type', txDetails?.beneficiary.id_type)
    setValue('beneficiary.id_number', txDetails?.beneficiary.id_number || '')
    setValue('beneficiary.code', txDetails?.beneficiary.code)
    setValue('beneficiary.email', txDetails?.beneficiary.email)
    setValue('beneficiary.date_of_birth', txDetails?.beneficiary.date_of_birth)
    setValue(
      'beneficiary.country_of_birth_iso_code',
      txDetails?.beneficiary.country_of_birth_iso_code
    )
    setValue(
      'beneficiary.nationality_country_iso_code',
      txDetails?.beneficiary.nationality_country_iso_code
    )
    setValue('beneficiary.address', txDetails?.sender.address)
    setValue('beneficiary.city', txDetails?.beneficiary.city)
    setValue('beneficiary.country_iso_code', txDetails?.beneficiary.country_iso_code)
    setValue('beneficiary.address', txDetails?.beneficiary.address)
    setValue('beneficiary.postal_code', txDetails?.beneficiary.postal_code)
    setValue('beneficiary.province_state', txDetails?.beneficiary.province_state)

    if (!txDetails?.creditPartyIdentifier.msisdn) {
      setValue('beneficiary.msisdn', txDetails?.beneficiary.msisdn)
    }
  }, [
    setValue,
    txDetails?.beneficiary.address,
    txDetails?.beneficiary.city,
    txDetails?.beneficiary.code,
    txDetails?.beneficiary.country_iso_code,
    txDetails?.beneficiary.country_of_birth_iso_code,
    txDetails?.beneficiary.date_of_birth,
    txDetails?.beneficiary.email,
    txDetails?.beneficiary.firstname,
    txDetails?.beneficiary.id_number,
    txDetails?.beneficiary.id_type,
    txDetails?.beneficiary.lastname,
    txDetails?.beneficiary.msisdn,
    txDetails?.beneficiary.nationality_country_iso_code,
    txDetails?.beneficiary.postal_code,
    txDetails?.beneficiary.province_state,
    txDetails?.creditPartyIdentifier.account_number,
    txDetails?.creditPartyIdentifier.bank_account_number,
    txDetails?.creditPartyIdentifier.bsb_number,
    txDetails?.creditPartyIdentifier.email,
    txDetails?.creditPartyIdentifier.iban,
    txDetails?.creditPartyIdentifier.ifs_code,
    txDetails?.creditPartyIdentifier.msisdn,
    txDetails?.creditPartyIdentifier.routing_code,
    txDetails?.creditPartyIdentifier.sort_code,
    txDetails?.creditPartyIdentifier.swift_bic_code,
    txDetails?.file,
    txDetails?.purposeOfRemittance,
    txDetails?.reference,
    txDetails?.sender.address,
    txDetails?.sender.city,
    txDetails?.sender.country_iso_code,
    txDetails?.sender.country_of_birth_iso_code,
    txDetails?.sender.date_of_birth,
    txDetails?.sender.email,
    txDetails?.sender.firstname,
    txDetails?.sender.id_number,
    txDetails?.sender.id_type,
    txDetails?.sender.lastname,
    txDetails?.sender.msisdn,
    txDetails?.sender.nationality_country_iso_code,
    txDetails?.sender.postal_code,
    txDetails?.sender.province_state,
  ])

  useEffect(() => {
    // if (txDetails) {
    setFormValue()
    // }
  }, [setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: NewTxDetailsFormType) => {
    const sender = commonUtils.trimObjectFields(
      pick(dataForm.sender, formFieldsConfig?.sender || [])
    ) as SenderForNewTxDetailsForm
    let beneficiary = commonUtils.trimObjectFields(
      pick(dataForm.beneficiary, formFieldsConfig?.beneficiary || [])
    ) as BeneficiaryForNewTxDetailsForm
    const creditPartyIdentifier = commonUtils.trimObjectFields(
      pick(dataForm.creditPartyIdentifier, formFieldsConfig?.creditPartyIdentifier || [])
    ) as CreditPartyIdentifierForNewTxDetailsForm
    const id_number = sender.id_number.replace(/[^a-zA-Z0-9]/g, '')

    if (formFieldsConfig?.beneficiary.includes('id_number')) {
      beneficiary = {
        ...beneficiary,
        ...{ id_number: beneficiary?.id_number?.replace(/[^a-zA-Z0-9]/g, '') },
      }
    }

    const modifiedDataForm = {
      // ...dataForm,
      sender: { ...sender, id_number },
      beneficiary,
      creditPartyIdentifier,
      file: dataForm.file,
      purposeOfRemittance: dataForm.purposeOfRemittance,
      // service id with value 2 is the 'BankAccount' service
      ...(currentServiceId === 2 ? { reference: dataForm.reference } : {}),
      ...(isValidPromoCode ? { promoCode: promoCodeInputValue.trim().toLocaleUpperCase() } : {}),
    }

    onParentSubmit(modifiedDataForm)
  }

  const onClickReset = () => {
    reset()
  }

  const maleCalc = useCallback(
    (promoCodeValue: string) => {
      if (calcDetails) {
        const body = {
          payerId: calcDetails.payers?.value || 0,
          sendCountryCode: calcDetails.srcCountry.isoCode3,
          sendCurrencyCode: calcDetails.srcCountry.cashInCurrency,
          receiveCountryCode: calcDetails.destCountry.isoCode3,
          receiveCurrencyCode: calcDetails.destCountry.cashInCurrency,
          mode: calcDetails.mode,
          amount:
            calcDetails.mode === 'SOURCE_AMOUNT'
              ? Number(calcDetails.srcAmount)
              : Number(calcDetails.destAmount),
          promoCode: promoCodeValue,
        }

        dispatch(calculationActionCreators.makeCalculation(body))
      }
    },
    [calcDetails, dispatch]
  )

  const onPromoCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const trimmedValue = value.trim()
    const regex = /^[A-Za-z0-9]{8,15}$/

    if (regex.test(trimmedValue)) {
      setPromoCodeInputError('')
    } else {
      setPromoCodeInputError('The code is not available')
    }

    setPromoCodeInputValue(value)
  }, [])

  const onApplyClick = useCallback(() => {
    const trimmedPromoCodeValue = promoCodeInputValue.trim().toUpperCase()

    if (trimmedPromoCodeValue && !promoCodeInputError) {
      dispatch(transactionActionCreators.checkPromoCode(promoCodeInputValue))
        .unwrap()
        .then((response: CheckPromoCodeResponse) => {
          setIsValidPromocode(response.isAvailable)

          if (response.isAvailable) {
            maleCalc(trimmedPromoCodeValue)
            setPromoCode(trimmedPromoCodeValue)
          } else {
            setPromoCodeInputError('The code is not available')
            setPromoCode('')
          }
        })
        .catch(error => {
          const e = error as CustomErrorResponse

          setPromoCodeInputError(e.message)
          setPromoCode('')
        })
    }
  }, [dispatch, maleCalc, promoCodeInputError, promoCodeInputValue, setPromoCode])

  const onDeletePromoCode = useCallback(() => {
    setIsValidPromocode(false)
    setPromoCodeInputValue('')
    setPromoCode('')

    maleCalc('')
  }, [maleCalc, setPromoCode])

  if (!isShownForm) {
    return null
  }

  return (
    <form
      id="tx-details-form"
      className="box is-white d-flex flex-col gap-24"
      style={{ position: 'relative' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      {(makeCalculationLoadState.isLoading || checkPromoCodeLoadState.isLoading) && (
        <div className="spinner spinner-absolute">
          <BeatLoader size={30} color="#3171d8" />
        </div>
      )}

      <div>
        <h4 className="mb-16">Sender</h4>

        <div className="fieldset">
          <FormInput<NewTxDetailsFormType>
            name="sender.firstname"
            control={control}
            placeholder="First name"
            label="First name"
            type="text"
            maxLength={64}
          />
          <FormInput<NewTxDetailsFormType>
            name="sender.lastname"
            control={control}
            placeholder="Last name"
            label="Last name"
            type="text"
            maxLength={64}
          />
        </div>

        <div className="fieldset" style={{ flexWrap: 'wrap' }}>
          <FormInput<NewTxDetailsFormType>
            name="sender.id_number"
            control={control}
            placeholder="ID number"
            label="ID number"
            type="text"
            maxLength={32}
          />
          {formFieldsConfig &&
            formFieldsConfig?.sender.map(field => (
              <CommonSenderFormField key={field} name={field} control={control} />
            ))}
        </div>
        {formFieldsConfig?.sender.includes('msisdn') && (
          <FormPhoneNumberInput<NewTxDetailsFormType>
            name="sender.msisdn"
            control={control}
            placeholder="Mobile number"
            label="Mobile number"
            defaultCountryIso2Code={sourceCountryIso2Code}
          />
        )}
      </div>

      <div className="d-flex flex-col gap-8">
        <FormFileInput<NewTxDetailsFormType> name="file" control={control} />
        {/* <Test name="file" control={control}/> */}

        <div className="divider">
          <hr />
          <div className="divider-icon">
            <Icon name="arrows-down" size={24} color="#D5DBE2"></Icon>
          </div>
          <hr />
        </div>

        <div>
          <h4 className="mb-16">Recipient</h4>
          <div className="fieldset">
            <FormInput<NewTxDetailsFormType>
              name="beneficiary.firstname"
              control={control}
              placeholder="First name"
              label="First name"
              type="text"
              maxLength={64}
            />
            <FormInput<NewTxDetailsFormType>
              name="beneficiary.lastname"
              control={control}
              placeholder="Last name"
              label="Last name"
              type="text"
              maxLength={64}
            />
          </div>
          <div className="fieldset" style={{ flexWrap: 'wrap' }}>
            {formFieldsConfig &&
              formFieldsConfig?.beneficiary.map(field => (
                <CommonBeneficiaryFormField key={field} name={field} control={control} />
              ))}
          </div>
        </div>
      </div>

      {formFieldsConfig?.beneficiary.includes('msisdn') &&
        !formFieldsConfig?.creditPartyIdentifier.includes('msisdn') && (
          <FormPhoneNumberInput<NewTxDetailsFormType>
            name="beneficiary.msisdn"
            control={control}
            placeholder="Mobile number"
            label="Mobile number"
            defaultCountryIso2Code={destinationCountryIso2Code}
          />
        )}

      {formFieldsConfig &&
        formFieldsConfig?.creditPartyIdentifier.map(field => (
          <CommonCreditPartyFormField
            key={field}
            name={field}
            control={control}
            destinationCountryIso2Code={destinationCountryIso2Code}
          />
        ))}

      {isShownReferenceField && (
        <FormInput<NewTxDetailsFormType>
          name="reference"
          control={control}
          placeholder="Reference"
          label="Reference"
          type="text"
          maxLength={256}
        />
      )}
      <div className="fieldset">
        <FormSelector<NewTxDetailsFormType>
          options={transactionUtils.purposeOfRemittanceOptions}
          name="purposeOfRemittance"
          control={control}
          styles={selectStyles}
          labelName="Purpose of remittance"
        />
      </div>

      <hr />
      {isValidPromoCode ? (
        <div className="d-flex align-items-center justify-content-center">
          <p>Promo Code: {promoCodeInputValue.trim()}</p>
          <button
            className="transparent-button d-flex ml-auto"
            type="button"
            onClick={onDeletePromoCode}
          >
            <Icon name="trash" size={16} color="#991B1B" />
          </button>
        </div>
      ) : (
        <div className={`form-group`}>
          <label className="form-control-label" htmlFor="promo-code">
            Enter a gift card, voucher or promotional code
          </label>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <input
              id="promo-code"
              className="form-control"
              style={{ flex: 1 }}
              onChange={onPromoCodeChange}
              value={promoCodeInputValue}
              type="text"
              placeholder="Enter code"
              maxLength={15}
            />
            <button type="button" className="btn btn-tertiary" onClick={onApplyClick}>
              Apply
            </button>
          </div>

          <p className="form-control-error">{promoCodeInputError}</p>
        </div>
      )}
      <hr />

      <div className="button-group">
        <button type="button" className="btn btn-tertiary" onClick={onClickReset}>
          Clear Form
        </button>
        <button
          form="tx-details-form"
          type="submit"
          className="btn btn-primary"
          disabled={isDisabledButton}
        >
          Continue
        </button>
      </div>
    </form>
  )
}

export default NewTxDetailsForm
