import { transactionActionCreators } from '@actionCreators/transactionActionCreators'
import CheckBox from '@components/calculation/ui/CheckBox'
import DialogModal from '@components/calculation/ui/DialogModal'
import Icon from '@components/Icon'
import { TransactionStatus } from '@customTypes/transaction'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import dateUtils from '@utils/date'
import transactionUtils from '@utils/transaction'
import { ChangeEvent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'

const CashOutLookUpPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { lookUpTransactionLoadState } = useAppSelector(state => state.transaction)

  const [inputValue, setInputValue] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [isShownTx, setIsShownTx] = useState(false)
  const [isIdChecked, setIsIdChecked] = useState(false)
  const [isOpenDialogModal, setIsOpenDialogModal] = useState(false)

  const onClickLookUp = useCallback(() => {
    dispatch(transactionActionCreators.lookUpTransaction(inputValue))
  }, [dispatch, inputValue])

  const onCompleteTransaction = useCallback(() => {
    setIsOpenDialogModal(true)
  }, [])

  const onChangeIdChecked = useCallback((value: boolean) => {
    setIsIdChecked(value)
  }, [])

  const confirmFunction = useCallback(() => {
    setIsOpenDialogModal(false)

    dispatch(transactionActionCreators.completeCashOutTransaction({}))
  }, [navigate])

  const cancelModalFunction = useCallback(() => {
    setIsOpenDialogModal(false)
  }, [])

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    setInputValue(value)
  }, [])

  return (
    <>
      {lookUpTransactionLoadState.isLoading && (
        <div className="spinner spinner-fixed">
          <BeatLoader size={30} color="#3171d8" />
        </div>
      )}
      <section className="form-section">
        <h3 className="t-center mb-40">Look Up Transaction</h3>
        <div className="container container-lg">
          <div className="background-container d-flex justify-content-center mb-24">
            <div className="review-container box is-transparent d-flex">
              <div className="review-dialog-wrapper">
                <div className="review-dialog box is-white d-flex flex-col gap-16 f-monrope-sm fw-500">
                  <div className={`form-group`}>
                    <label className="form-control-label" htmlFor="promo-code">
                      Enter a redeem code
                    </label>
                    <input
                      id="promo-code"
                      className="form-control"
                      onChange={onInputChange}
                      value={inputValue}
                      type="text"
                      placeholder="Enter code"
                      maxLength={15}
                    />

                    {/* <p className="form-control-error">{promoCodeInputError}</p> */}
                  </div>
                  <hr />
                  <div className="button-group flex-wrap">
                    <button
                      type="button"
                      className="btn btn-primary confirm-button"
                      onClick={onClickLookUp}
                    >
                      Look Up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isShownTx && (
            <div className="background-container d-flex justify-content-center">
              <div className="review-container box is-transparent d-flex">
                <div className="review-dialog-wrapper">
                  <div className="review-dialog box is-white d-flex flex-col gap-16 f-monrope-sm fw-500">
                    <div>
                      <div className="h5 mb-8">Transaction Details</div>
                      <hr />
                    </div>
                    <div>
                      <div>
                        <div className="form-row mb-8">
                          <div className="d-flex align-items-center gap-8">
                            Sent Amount <Icon name="arrow-left" size={16} color="#0C9B00" />
                          </div>
                        </div>
                        <div className="form-row mb-8">
                          <div className="h3">
                            <span style={{ color: '#0C9B00' }}>123.10 USD</span>
                          </div>
                          <div>Status</div>
                        </div>

                        <div className="form-row mb-8">
                          <div className="d-flex align-items-center gap-8">
                            Receive Amount <Icon name="arrow-right" size={16} color="#324eef" />
                          </div>
                          <div className="d-flex align-items-center gap-4 h5">
                            <Icon
                              name="ellipse"
                              size={10}
                              color={transactionUtils.getColorByTxStatus(
                                TransactionStatus.PAYOUT_PROCESSING
                              )}
                            />{' '}
                            {transactionUtils.getTxStatus(TransactionStatus.PAYOUT_PROCESSING)}
                          </div>
                        </div>
                        <div className="h3" style={{ color: '#324eef' }}>
                          123.10 USD
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-4">Time and Date</p>
                        <p className="fw-700">
                          {dateUtils.getFormattedDate(
                            '2024-11-07T11:14:39.956Z',
                            'hh:mmaaa, dd/MM/yyyy'
                          )}
                        </p>
                      </div>
                      <div className="transaction-id">
                        <p className="mb-4">Transaction ID</p>
                        <p className="fw-700">{1212312454}</p>
                      </div>
                    </div>

                    <hr />

                    <div>
                      <dl className="definition-list gap-8">
                        <dt>Payout Type</dt>
                        <dd>Cash</dd>

                        <dt>Payout Method</dt>
                        <dd>Cash Pickup</dd>

                        <dt>Payout Fee</dt>
                        <dd>-5.00 USD</dd>

                        <dt>Fee discount</dt>
                        <dd style={{ color: '#B91C1C' }}>0.54 USD</dd>

                        <dt>Total to pay </dt>
                        <dd>1234.00 USD</dd>
                      </dl>
                    </div>

                    <hr />

                    <div className="mb-8">
                      <h4 className="mb-8">Sender</h4>
                      <hr />
                    </div>

                    <div>
                      <dl className="definition-list gap-8">
                        <dt>First and last name</dt>
                        <dd>Kolin Farel</dd>
                        <dt>ID number</dt>
                        <dd>NG 123231</dd>
                      </dl>
                    </div>

                    <div className="mb-8">
                      <h4 className="mb-8">Recipient</h4>
                      <hr />
                    </div>

                    <div>
                      <dl className="definition-list gap-8">
                        <dt>First and last name</dt>
                        <dd>Marg Simpson</dd>
                        <dt>ID number</dt>
                        <dd>NG 123231</dd>
                      </dl>
                    </div>

                    <hr />
                    <div>
                      <dl className="definition-list gap-8">
                        <dt>Purpose of transfer</dt>
                        <dd>Computer service</dd>
                      </dl>
                    </div>

                    <hr />

                    <CheckBox label="Id checked" onChangeParentCheckBox={onChangeIdChecked} />

                    <div className="button-group flex-wrap">
                      <button
                        type="button"
                        className="btn btn-primary confirm-button"
                        onClick={onCompleteTransaction}
                        disabled={!isIdChecked}
                      >
                        Complete Transactions
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <DialogModal
        isOpenModal={isOpenDialogModal}
        confirmFunction={confirmFunction}
        cancelFunction={cancelModalFunction}
        titleText="Complete Transaction"
        bodyComponent="Do you confirm that you want to complete the transaction?"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
      />
    </>
  )
}

export default CashOutLookUpPage
