import { ChangeEvent, useCallback, useEffect, useState } from "react"

type CheckBoxProps = {
  label: string
  isParentChecked?: boolean
  onChangeParentCheckBox: (value: boolean) => void
}

const CheckBox = ({ label, isParentChecked, onChangeParentCheckBox }: CheckBoxProps) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    if (isParentChecked) {
      setIsChecked(isParentChecked)
    }
  }, [isParentChecked])

  const onChangeCheckBox = useCallback((event: ChangeEvent<HTMLInputElement>)=>{
    const value = event.target.checked

    setIsChecked(value)
    onChangeParentCheckBox(value)

  },[onChangeParentCheckBox])
  return (
    <div className="checkboxes__item">
      <label className="checkbox">
        <input type="checkbox" checked={isChecked} onChange={onChangeCheckBox}/>
        <div className="checkbox__checkmark"></div>
        <div className="checkbox__body">{label}</div>
      </label>
    </div>
  )
}

export default CheckBox
